
import './App.css';
import React, { Component } from 'react'; //different
import instance from "./axios-instance";
import Systems from "./SystemFunction";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            activePage: 1,
            isActive: false,
            rewardlist: [],
            activeTab: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            userid: null,
            point: 0,
            balance: 0,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            modalOpen: false,
            handleClose: false,
            token: window.localStorage.getItem("token"),
            type: "",
            amount: "",
            remark: "",
            name: "",
            password: "",
            linelink: "",
            left: false,
            changepassword: false,
        }
    }
  async  componentDidMount() {
        this.setState({
            pathname: window.location.pathname.replace("/", "").trim()
        });
        try {
            await instance.post("/api/v1/mainsetting", {
                System: Systems,
              }).then((res) => {
                    if (res.data.status === 200) {
                        const datas = res.data.message;
                        this.setState({
                            linelink: datas[1].value
                        });
                    }
                    else {
                        // this.CheckThisGoalError();
                    }
                });
        }
        catch (error) {
            //////console.log();
        }
    }
    a
    render() {
        const pathname = this.state.pathname
        return (

            <div className="-menu-index-page" style={{ position: "relative" }}>
                <div data-menu-sticky="js-sticky-widget" className="js-sticky-widget sticksy-dummy-node"  style={{overflowX:"hidden"}} >
                    <div className="x-menu-provider js-tab-menu-provider -background-image-pattern  " >
                        <nav className="nav-menu" id="navbarCategory">
                            <ul className="nav nav-pills js-menu-container -nav-menu-container">
                                <li className="nav-item">
                                    <button
                                        className={"nav-link -hot-game " + (pathname === "" ? "active" : "")}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location.href = '/';
                                        }}
                                    >
                                        <img
                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-hot-game.png"
                                            alt="คาสิโนออนไลน์ บาคาร่า สล๊อตออนไลน์ ยอดนิยม"
                                            className="img-fluid -ic-menu"
                                            width={55}
                                            height={55}
                                        />
                                        <div className="-text-provider-wrapper">
                                            <div className="-text-nav-menu -title">HOT GAME</div>
                                            <div className="-text-nav-menu -title-trans">ยอดนิยม</div>
                                            <div className="-text-nav-menu -title-mobile">ยอดนิยม</div>
                                        </div>
                                    </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={"nav-link -casino " + (pathname === "casino" ? "active" : "")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/casino';
                                    }}
                                >
                                    <img
                                        src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-casino.png"
                                        alt="คาสิโน บาคาร่า รูเล็ต ซิคโบ ไฮโล"
                                        className="img-fluid -ic-menu"
                                        width={55}
                                        height={55}
                                    />
                                    <div className="-text-provider-wrapper">
                                        <div className="-text-nav-menu -title">CASINO</div>
                                        <div className="-text-nav-menu -title-trans">คาสิโนสด</div>
                                        <div className="-text-nav-menu -title-mobile">คาสิโน</div>
                                    </div>
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className={"nav-link -slot " + (pathname === "slot" ? "active" : "")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/slot';
                                    }}>
                                    <img
                                        src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-slot.png"
                                        alt="บริการสล็อตออนไลน์ ฝาก 100 รับเครดิตฟรี 100"
                                        className="img-fluid -ic-menu"
                                        width={55}
                                        height={55}
                                    />
                                    <div className="-text-provider-wrapper">
                                        <div className="-text-nav-menu -title">SLOT</div>
                                        <div className="-text-nav-menu -title-trans">สล็อตเกมส์</div>
                                        <div className="-text-nav-menu -title-mobile">สล็อต</div>
                                    </div>
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className={"nav-link -sport " + (pathname === "sport" ? "active" : "")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/sport';
                                    }}
                                >
                                    <img
                                        src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-sport.png"
                                        alt="แทงฟุตบอลพนันออนไลน์ บาสเก็ตบอล E-Sport"
                                        className="img-fluid -ic-menu"
                                        width={55}
                                        height={55}
                                    />
                                    <div className="-text-provider-wrapper">
                                        <div className="-text-nav-menu -title">SPORT</div>
                                        <div className="-text-nav-menu -title-trans">กีฬา</div>
                                        <div className="-text-nav-menu -title-mobile">กีฬา</div>
                                    </div>
                                </button>
                            </li>
                            {/* <li className="nav-item" style={{display:"none"}}>
                                <button className={"nav-link -slot " + (pathname === "arcade" ? "active" : "")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/arcade';
                                    }}>
                                    <img
                                        src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-e-sport.png"
                                        alt="บริการสล็อตออนไลน์ ฝาก 100 รับเครดิตฟรี 100"
                                        className="img-fluid -ic-menu"
                                        width={55}
                                        height={55}
                                    />
                                    <div className="-text-provider-wrapper">
                                        <div className="-text-nav-menu -title">Arcade</div>
                                        <div className="-text-nav-menu -title-trans">อาร์เคด</div>
                                        <div className="-text-nav-menu -title-mobile">อาร์เคด</div>
                                    </div>
                                </button>
                            </li> */}
                            {/* */}
                            {/* <li className="nav-item"  style={{display:"none"}}>
                                <button
                                    className={"nav-link -fishing-game " + (pathname === "fising" ? "active" : "")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/fising';
                                    }}
                                >
                                    <img
                                        src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-fishing-game.png"
                                        alt="ยิงปลาได้เงินจริง เครดิตฟรีแรกฝาก"
                                        className="img-fluid -ic-menu"
                                        width={55}
                                        height={55}
                                    />
                                    <div className="-text-provider-wrapper">
                                        <div className="-text-nav-menu -title">FISHING</div>
                                        <div className="-text-nav-menu -title-trans">ยิงปลา</div>
                                        <div className="-text-nav-menu -title-mobile">ยิงปลา</div>
                                    </div>
                                </button>
                            </li> */}
                            {/* <li className="nav-item"  style={{display:"none"}}>
                                <button
                                    className={"nav-link -fishing-game " + (pathname === "fising" ? "active" : "")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/freegame';
                                    }}
                                >
                                    <img
                                        src="/build/web/igame-index-lobby-wm/img/ajaxx-ic-nav-menu-slot.png"
                                        alt="ยิงปลาได้เงินจริง เครดิตฟรีแรกฝาก"
                                        className="img-fluid -ic-menu"
                                        width={55}
                                        height={55}
                                    />
                                    <div className="-text-provider-wrapper">
                                        <div className="-text-nav-menu -title">FREEGAME</div>
                                        <div className="-text-nav-menu -title-trans">ฟรีเกม</div>
                                        <div className="-text-nav-menu -title-mobile">ฟรีเกม</div>
                                    </div>
                                </button>
                            </li> */}
                        </ul>
                        <div className="-contact-wrapper  " >
                            <div className="x-contact-us -multiple">
                                <img
                                    alt=""
                                    className="img-fluid -cover-img ls-is-cached lazyloaded"
                                    src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-cover.png"
                                />
                                <div className="-btn-group-wrapper">
                                    <a
                                        href={this.state.linelink}
                                        className="-link-wrapper -line-wrapper"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            alt=""
                                            className="img-fluid -img ls-is-cached lazyloaded"
                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-line.png"
                                        />
                                    </a>
                                    <a
                                        href="XXXXXXX"
                                        className="-link-wrapper -telegram-wrapper"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            alt=""
                                            className="img-fluid -img ls-is-cached lazyloaded"
                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-telegram.png"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>

                    </nav>
                </div>
            </div>

            </div >
        );
    }
}

export default App;
