import React, { Component } from 'react'; //different
//import './style.css';
import NavMenu from './navmenu';
class App extends Component {
    componentDidMount() {
    }
    render() {
        return (
            <div className="-banner-wrapper">
                <div data-slickable="{&quot;arrows&quot;:false,&quot;dots&quot;:true,&quot;slidesToShow&quot;:1,&quot;centerMode&quot;:true,&quot;infinite&quot;:true,&quot;autoplay&quot;:true,&quot;autoplaySpeed&quot;:4000,&quot;pauseOnHover&quot;:false,&quot;focusOnSelect&quot;:true,&quot;variableWidth&quot;:true,&quot;responsive&quot;:{&quot;sm&quot;:{&quot;fade&quot;:true,&quot;variableWidth&quot;:false}}}" className="x-banner-slide-wrapper -single">
                    <div className="-slide-inner-wrapper -slick-item">
                        <a href="/promotions" className="-link-wrapper">
                            <img src="/media/cache/strip/202109/block/123.png" alt="" className="img-fluid -slick-item -item-1" width={1200} height={590} />
                        </a>
                    </div>
                    <div className="-slide-inner-wrapper -slick-item">
                        <a href="/promotions" className="-link-wrapper">
                            <img src="/media/cache/strip/202109/block/124.png" alt="" className="img-fluid -slick-item -item-2" width={1200} height={590} />
                        </a>
                    </div>
                    <div className="-slide-inner-wrapper -slick-item">
                        <a href="/promotions" className="-link-wrapper">
                            <img src="/media/cache/strip/202109/block/125.png" alt="" className="img-fluid -slick-item -item-3" width={1200} height={590} />
                        </a>
                    </div>
                    <div className="-slide-inner-wrapper -slick-item">
                        <a href="/promotions" className="-link-wrapper">
                            <img src="/media/cache/strip/202109/block/126.png" alt="" className="img-fluid -slick-item -item-4" width={1200} height={590} />
                        </a>
                    </div>
                </div>
            </div>

        );
    }
}
export default App;
