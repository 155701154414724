import React, { Component } from 'react'; //different
//import './style.css';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            products_page_count: 1,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            prolistx: [],
            username: null,
            userid: null,
            phoneNumber: "",
            balance: 0,
            mainacc: null,
            qrcode: null,
            stateregis: 1,
            checkboxacc: 0,
            loginstate: false,
            fullname: null,
            lname: null,
            alert: null,
            depositstate: 0,
            depositamount: 0,
            token: window.localStorage.getItem("token"),
            accnumber: null,
            accnumbertxt: null,
            withdrawamount: 0,
            rankpoint: 0,
            changepassword: false,
            linelink: "",
            errortext: "",
            affpercent: 0,
            userrecomend: "",
            historylist: [],
            useradvice: [],
            tblnextrank: [],
            tblrankall: [],
            tblprofile: [],
            prodetail: [],
            linkaff: "",
            picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
            proderedeem: [],
            buttoncliam: false,
            limit: null,
            rankpic: null,
            rankname: null,
            rankob: null,
            termAndCondition: false,
            errorstatus: false,
        }
    }
    confirmdeposit = async (e, num) => {

        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;

        await instance.post("/api/v1/getredeempromotion", {
            userid: Username,
            System: Systems,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    proderedeem: res.data.message.tblpromotionredeems,
                });

            }
        }).catch(async (err) => {
        });

        if (num === 0) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                const { depositstate } = this.state
                ////////console.log(depositstate, num);
                if (num === 0) {
                    if (token != null) {
                        instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            ////////console.log(res.data.picture)
                            this.setState({
                                mainacc: res.data.mainacc,
                                qrcode: res.data.picture
                            });
                        });
                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
            }
        }
        else if (num === 1) {
            this.setState({
                depositstate: 1
            });
        }
        else {
            this.setState({
                depositstate: 0
            });
        }



    }
    async componentDidMount() {
        this.promotionlist();
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            this.setState({
                username: Username,
            });

        }
    }
    promotionlist = () => {
        instance.post("/api/v1/getpromotion", {
            System: Systems,
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    prolistx: res.data.message,
                });
            }
        }).catch(async (err) => {
            //   console.log(err);

            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await Swal.fire({
                    title: 'Warning!',
                    text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
                window.location.href = '/';
            }
        });
    }
    redeempro = async (e, proid) => {
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        //console.log(Username, proid);
        if (token != null) {
            this.setState({
                username: Username,
            });
            await instance.post("/api/v1/redeempromotion", {
                userid: Username,
                System: Systems,
                Proid: proid,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //console.log(res.data.status)
                if (res.data.status === 200) {
                    document.getElementById("opendepositsuper").click()
                }
                else if (res.data.status === 201) {
                    await Swal.fire({
                        title: 'error!',
                        text: 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้',
                        icon: 'error',
                        confirmButtonText: 'ตกลง'
                    })
                    e.preventDefault();
                    return false;
                }
                else if (res.data.status === 204) {
                    await Swal.fire({
                        title: 'error!',
                        text: 'ท่านใช้สิทธวันนี้ครบแล้ว',
                        icon: 'error',
                        confirmButtonText: 'ตกลง'
                    })
                    e.preventDefault();
                    return false;
                }
                else if (res.data.status === 202) {
                    await Swal.fire({
                        title: 'error!',
                        text: 'ท่านใช้โปรมั่นชั่นนี้ไปแล้ว',
                        icon: 'error',
                        confirmButtonText: 'ตกลง'
                    })
                    e.preventDefault();
                    return false;
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
    }

    checkpro = async (e, proid) => {
        var Username = "";
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            Username = decoded.message.playerid;
        }
        await instance.post("/api/v1/checkpromotion", {
            userid: Username,
            System: Systems,
            Proid: proid,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                //console.log(res.data);
                if (res.data.type === 0) {
                    this.setState({
                        prodetail: res.data.message.tblpromotions[0],
                    });
                }
                else if (res.data.type === 1) {
                    this.setState({
                        proderedeem: res.data.message.tblpromotionredeems,
                    });
                }

            }
            else if (res.data.status === 201) {
                this.setState({
                    prodetail: [],
                    picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
                });

            }
            else if (res.data.status === 202) {
                await Swal.fire({
                    title: 'error!',
                    text: 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย',
                    icon: 'error',
                    confirmButtonText: 'ตกลง'
                })
                return false;
            }

        }).catch(async (err) => {
            //   console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await Swal.fire({
                    title: 'Warning!',
                    text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
                window.location.href = '/';
            }
        });
    }


    render() {
        const { prodetail, picnonpro, username, prolistx } = this.state
        //console.log(prodetail.length)
        return (
            <div className="-full-flex-wrap ">
                <div
                    className=""
                    style={{
                        backgroundImage: "url(build/web/igame-index-lobby-wm/img/index-bg.jpg)",
                        height: "100%"
                    }}
                >
                    <div className="x-promotion-index">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="x-page-title-component ">
                                        <div className="-inner-wrapper">
                                            <h1 className="-title">โปรโมชั่น</h1>
                                            <img
                                                src="/build/web/igame-index-lobby-wm/img/line-glow-blue.png"
                                                className="-line-img"
                                                alt="Page title line glow"
                                            />
                                            <img
                                                src="/build/web/igame-index-lobby-wm/img/index-lobby-ic-header-menu-promotion.png"
                                                className="-img -item-1"
                                                alt="Page title icon"
                                            />
                                            <img
                                                src="/build/web/igame-index-lobby-wm/img/index-lobby-ic-header-menu-promotion.png"
                                                className="-img -item-2"
                                                alt="Page title icon"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row -row-wrapper x-divine-gutter">
                                {prolistx.map(prolists => (
                                    <React.Fragment key={prolists.no}>
                                        <div
                                            className="col-12 col-md-6 mt-3 -col-wrapper x-divine-gutter"
                                            data-delay={100}
                                        >
                                            <a
                                                href="#0"
                                                onClick={(e) => this.checkpro(e, prolists.no)}
                                                data-target="#ProDetailModalsuper"
                                                data-toggle="modal"
                                                data-force="true"
                                                className="x-promotion-list-item "
                                            >
                                                <img
                                                    alt=""
                                                    className="-cover-img img-fluid"
                                                    width={200}
                                                    height={200}
                                                    src={prolists.linkpicture}

                                                />
                                            </a>
                                        </div>
                                    </React.Fragment>

                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="ProDetailModalsuper"
                    tabIndex={-1}

                    className="modal x-modal -promotion-detail-modal promotion-detail-modal-149 show"
                    aria-modal="true"
                >
                    <div
                        className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable -modal-mobile"
                        role="document"
                    >
                        <div className="modal-content -modal-content">
                            <button
                                type="button"
                                className="close f-1 "
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fas fa-times" />
                            </button>
                            <div className="modal-body -modal-body">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 mt-4">
                                            <div className="x-page-title-component -midsize">
                                                <div className="-inner-wrapper">
                                                    <div className="-title">โปรโมชั่น</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column" >
                                    {(prodetail.length === 0) ? <div className="x-card card -card-promotion-detail ">
                                        <div className="card-body">
                                            <div className="-title">โปรโมชั่นยังไม่ได้เปิดใช้งาน</div>
                                            <div className="-img-container">
                                                <img
                                                    src={picnonpro}
                                                    alt="โปรโมชั่นยังไม่ได้เปิดใช้งาน"
                                                    className="-img-promotion img-fluid"
                                                    width={398}
                                                    height={143}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                        :
                                        <div className="-real-content" style={{ fontSize: "1em !important" }}>
                                            <div className="x-card card -card-promotion-detail ">
                                                <section
                                                    dangerouslySetInnerHTML={{ __html: prodetail.detail }}
                                                />
                                                <div className="card-footer" style={{ display: (prodetail.prolist == 5) ? "none" : "" }}>
                                                    <button
                                                        className="btn "

                                                        style={{ fontWeight: "300", background: "radial-gradient(100% 100% at 50% 0, #f9cf95 0, #c69a5d 100%)", display: (username !== null) ? "" : "none" }}
                                                        onClick={(e) => this.redeempro(e, prodetail.no)}
                                                    >
                                                        <span>รับโปรโมชั่น</span>
                                                    </button>
                                                    <button id="opendepositsuper" onClick={(e) => this.confirmdeposit(e, 4)} className="btn -btn -get-promotion-btn  " style={{ display: "none" }} data-toggle="modal" data-target="#depositModal"  >
                                                    </button>
                                                </div>
                                            </div>
                                        </div>}

                                    <a
                                        href="#deposit-choose-promotion"
                                        className="js-account-approve-aware btn -back-btn"
                                        data-toggle="modal"
                                        data-dismiss="modal"
                                    >
                                        <i className="fas fa-arrow-left" />
                                        <div className="f-6 -text">ย้อนกลับ</div>
                                    </a>
                                    <div className="mx-3 mb-3">
                                        <div className="x-admin-contact -no-fixed">
                                            <span className="x-text-with-link-component">
                                                <label className="-text-message ">ติดปัญหา</label>
                                                <a
                                                    href={this.state.linelink}
                                                    className="-link-message "
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <u>ติดต่อฝ่ายบริการลูกค้า</u>
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default App;
